import { Label } from '@cotiss/common/components/label.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { useCallback, useState } from 'react'
import { Text } from '@cotiss/common/components/text.component'
import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { GqlEvaluationFieldsFragment } from '@gql'
import { EvaluationEventRatingScaleValueField } from '@cotiss/evaluation-event/components/evaluation-event-rating-scale-value-field.component'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useDebounceCallback } from 'usehooks-ts'

type Props = {
  evaluation: GqlEvaluationFieldsFragment
  hasCriteria: boolean
}

export const EvaluationEventEnvelopeCommentaryEvaluation = ({ evaluation, hasCriteria }: Props) => {
  const { openToast } = useToast()

  const { mutateUpdateEvaluation, queryEvaluationView } = useEvaluation()
  const { evaluationEnvelope } = useEvaluationEnvelope()
  const [comment, setComment] = useState<string | undefined>(evaluation.comment ?? undefined)

  const [scoreValue, setScoreValue] = useState<number | undefined>(evaluation.value ?? undefined)

  const isDisabled = !evaluation || evaluation.status === 'complete' || evaluation.status === 'abstained'
  const isWeightedEnvelope = evaluationEnvelope?.weight !== 0

  const isScoreable = isWeightedEnvelope && !hasCriteria
  const isCommentRequired = !isWeightedEnvelope && !hasCriteria

  const evaluationId = evaluation?.id

  const handleSubmit = useCallback(
    async ({ value, comment }: { value?: number; comment?: string }) => {
      try {
        if (!evaluationId) {
          return
        }

        await mutateUpdateEvaluation({
          evaluationId: evaluation.id,
          value,
          comment,
        })
        await queryEvaluationView({ evaluationId })
      } catch (error: any) {
        sentryService.captureException({ exception: error })
        openToast(error.message, 'danger')
      }
    },
    [evaluationId]
  )

  const debouncedHandler = useDebounceCallback(handleSubmit, 500)

  function handleCommentChange(newComment: string) {
    setComment(newComment)
    debouncedHandler({ comment: newComment, value: scoreValue })
  }

  return (
    <div className="grid grid-cols-3 gap-6 py-1">
      {isScoreable ? (
        <>
          <div className="col-span-1">
            <Label>Score</Label>
            <Text size="sm" variant="light">
              Provide a score to evaluate this envelope
            </Text>
          </div>
          <div className="col-span-2">
            {evaluationEnvelope?.defaultRatingScale ? (
              <EvaluationEventRatingScaleValueField
                ratingScale={evaluationEnvelope?.defaultRatingScale}
                value={scoreValue?.toString()}
                onSubmit={(value) => {
                  setScoreValue(Number(value))
                  handleSubmit({ value: Number(value), comment })
                }}
                isDisabled={isDisabled}
              />
            ) : null}
          </div>
        </>
      ) : null}
      <div className="col-span-1">
        <div className="max-w-xs">
          <Label>
            Commentary{' '}
            {!isCommentRequired ? (
              <Text isInline variant="light" size="inherit">
                (optional)
              </Text>
            ) : null}
          </Label>
          <Text variant="light" size="sm">
            Provide commentary against the entire envelope for this submission
          </Text>
        </div>
      </div>
      <div className="col-span-2">
        <TextArea
          className="w-full"
          value={comment || ''}
          onChange={({ target }) => handleCommentChange(target.value)}
          rows={6}
          placeholder="Type comment"
          isDisabled={isDisabled}
        />
      </div>
    </div>
  )
}
